import Typography from '../../../components/Typography/Typography';
// External library imports
import _ from 'lodash';
// Css module imports
import classes from '../../../styles/CapacityContent.module.css';

const CapacityContent = ({ data }) => {
    const length = Math.floor(_.get(data, ['overall'], []).length / 3);

    return (
        <div className={`${classes.CapacityContent} ${classes.InfoModalContainer}`}>
            <div>
                <div className={classes.InfoModalHeading}>
                    <Typography content={'Bid Capacity'} size={14} />
                </div>
                <div className={classes.Content}>
                    <div className={classes.ContainerStart}>
                        {[0, 1, 2].map((n) => (
                            <table>
                                <tr>
                                    <th>Slot</th>
                                    <th>Hour</th>
                                    <th>Capacity</th>
                                </tr>
                                {Array.from({ length: length }, (_, i) => i + n * length).map((key) =>
                                    key <= 23 ? (
                                        <tr>
                                            <td>{key + 1}</td>
                                            <td>
                                                {key}-{key + 1}
                                            </td>
                                            <td>{_.get(data, ['overall', key])}</td>
                                        </tr>
                                    ) : null
                                )}
                            </table>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CapacityContent;
